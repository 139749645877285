<template>
  <main class="page-my-profile page-change-my-password">
    <PageTitle centered>Cambia password</PageTitle>
    <div class="container">
      <div class="row">
        <ProfilePasswordChangeForm class="col-md-6 offset-md-3" :title="null" />
      </div>
    </div>
  </main>
</template>

<script>

import PageTitle from '@/views/components/Private/PageTitle.vue';
import ProfilePasswordChangeForm from '@/views/components/Account/ProfilePasswordChangeForm.vue';

export default {
  components: {
    PageTitle,
    ProfilePasswordChangeForm,
  },
};

</script>
